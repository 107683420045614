// app/javascript/controllers/quantity_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["select"];

    connect() {
        console.log("Quantity controller connected!");
    }

    updateQuantity() {
        this.element.submit();
    }
}
