// app/javascript/controllers/product_controller.js
import { Controller } from "@hotwired/stimulus"
import axios from 'axios';
import Konva from "konva";

export default class extends Controller {

    static values = { id: Number, colorCode: String }

    async connect() {
        try {
            await this.loadKonvaLibrary();
            this.loadProduct();
        } catch (error) {
            console.error('Failed to initialize product view:', error);
        }
        window.addEventListener('scroll', this.handleScroll.bind(this));
        window.addEventListener('resize', this.scaleStageToFitContainer.bind(this));

    }

    disconnect() {
        window.removeEventListener('scroll', this.handleScroll.bind(this));
        window.removeEventListener('resize', this.scaleStageToFitContainer.bind(this));
    }

    loadKonvaLibrary() {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = 'https://unpkg.com/konva@latest/konva.min.js'; // URL of the Konva UMD CDN
            script.onload = () => resolve();
            script.onerror = () => reject('Failed to load Konva library');
            document.head.append(script);
        });
    }

    loadImage(src) {
        return new Promise((resolve, reject) => {
            if (!src) {
                reject('Image source URL is undefined.');
                return;
            }
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = (err) => reject(`Failed to load image with src ${src}: ${err}`);
            img.crossOrigin = "Anonymous";
            img.src = src;
        });
    }

    async assembleGallery(templateMaps, designs) {
        const container = document.getElementById('product-gallery-container');
        if (!container || container.offsetWidth === 0 || container.offsetHeight === 0) {
            console.error('Invalid container dimensions.');
            return;
        }

        this.stage = new Konva.Stage({
            container: 'product-gallery-container',
            width: container.offsetWidth,
            height: container.offsetHeight
        });

        for (const templateMap of templateMaps) {
            const image = await this.loadImage(templateMap.image_url).catch(console.error);
            if (image) {
                const layer = this.createLayerWithImage(image, 0, 0, container.offsetWidth, container.offsetHeight);
                this.stage.add(layer);
            }
        }

        designs.forEach(design => {
            if (design.type === "text") {
                const textLayer = this.createTextLayer({
                    text: design.text,
                    fontSize: design.fontSize,
                    fontFamily: design.fontFamily,
                    fill: design.color,
                    x: design.x,
                    y: design.y
                });
                this.stage.add(textLayer);
            }
        });

        this.stage.draw();
    }

    createThumbnail(templateMap, productDesigns) {
        const container = document.createElement('div');
        container.className = 'product-thumbnail';

        const img = new Image();
        img.src = templateMap.image_url;
        img.className = 'thumbnail-image';
        img.style.width = '100%';
        img.style.height = 'auto';
        img.style.objectFit = 'contain'; // Ensures the image fits within the container without distortion

        container.appendChild(img);

        container.addEventListener('click', () => {
            this.updateMainImage(templateMap, productDesigns);
        });

        return container;
    }



    createKonvaGallery(productTemplateMaps, productDesigns) {
        const enabledTemplateMaps = productTemplateMaps.filter(map => map.enabled);
        const templateData = enabledTemplateMaps.length > 0 ? enabledTemplateMaps[0] : null;

        const thumbnailContainer = document.getElementById('product-gallery-thumbnail-list');
        thumbnailContainer.innerHTML = ''; // Clear previous thumbnails

        for (const templateMap of enabledTemplateMaps) {
            const thumbnail = this.createThumbnail(templateMap);
            thumbnailContainer.appendChild(thumbnail);

            thumbnail.addEventListener('click', () => {
                this.updateMainImage(templateMap, productDesigns);
            });
        }

        this.updateMainImage(templateData, productDesigns);
    }


    updateMainImage(templateMap, productDesigns) {
        const container = document.getElementById('product-gallery-container');
        if (!container || !templateMap) {
            console.error('Invalid container or template data.');
            return;
        }

        const width = container.offsetWidth;
        const height = width * 2;

        if (!this.stage) {
            this.stage = new Konva.Stage({
                container: 'product-gallery-large-photo',
                width: width,
                height: height,
            });
        } else {
            this.stage.width(width);
            this.stage.height(height);
            this.stage.scale({ x: 1, y: 1 }); // Reset scale
            this.stage.destroyChildren(); // Clear previous layers
        }

        this.createKonvaGalleryForTemplate(templateMap, productDesigns);
    }




    async createKonvaGalleryForTemplate(templateData, productDesigns) {
        if (!templateData) {
            console.error('Template data is missing.');
            return;
        }

        // Ensure productDesigns is defined
        if (!productDesigns) {
            //console.error('Product designs data is missing.');
            return;
        }

        // Load the template image and create a layer for it
        try {
            const templateImage = await this.loadImage(templateData.image_url);
            const templateLayer = this.createLayerWithImage(templateImage, templateData.x, templateData.y, 'templateLayer', templateData.print_width, templateData.print_height);
            this.stage.add(templateLayer);
        } catch (error) {
            console.error("Error loading template image:", error);
        }

        // Create group with clipping function
        const group = new Konva.Group({
            width: templateData.print_area_width,
            height: templateData.print_area_height,
            clipFunc: (context) => {
                context.rect(
                    templateData.print_area_left,
                    templateData.print_area_top,
                    templateData.print_area_width,
                    templateData.print_area_height
                );
            },
        });

        // Filter designs based on the current template placement
        const filteredDesigns = productDesigns.filter(design => design.placement === templateData.placement);

        for (const design of filteredDesigns) {
            switch (design.type) {
                case "text":
                    const textLayer = this.createTextLayer(
                        design.id,
                        design.fill,
                        design.fontFamily,
                        design.fontSize,
                        design.height,
                        design.placement,
                        design.rotation,
                        design.scaleX,
                        design.scaleY,
                        design.text,
                        design.x,
                        design.y,
                        design.data,
                        design.width,
                    );
                    this.stage.add(textLayer);
                    break;
                case "pattern":
                    const patternLayer = await this.createPatternLayer(design, templateData);
                    this.stage.add(patternLayer);
                    break;
                case "path":
                    const pathLayer = this.createPathLayer(
                        design.id,
                        design.x,
                        design.y,
                        design.data,
                        design.fill || 'transparent',
                        design.stroke || 'black',
                        design.strokeWidth || 0,
                        design.scaleX || 1,
                        design.scaleY || 1,
                        design.rotation || 0
                    );
                    this.stage.add(pathLayer);
                    break;
                default:
                    const isSVG = design.data && design.data.startsWith('data:image/svg+xml;base64');
                    const src = isSVG ? design.data : design.s3Url;
                    try {
                        const elementImage = await this.loadImage(src);
                        const elementLayer = this.createLayerWithElementImage(elementImage, design.x, design.y, `elementLayer_${design.id}`, group, design.width, design.height, design.rotation);
                        this.stage.add(elementLayer);
                    } catch (error) {
                        console.error("Error loading element image:", error);
                    }
            }
        }

        this.scaleStageToFitContainer(templateData);
    }


    async createPatternLayer(pattern, templateData) {
        const layer = new Konva.Layer({
            clipFunc: (context) => {
                context.rect(
                    templateData.print_area_left,
                    templateData.print_area_top,
                    templateData.print_area_width,
                    templateData.print_area_height
                );
            }}
        );
        const group = new Konva.Group({
            x: pattern.x,
            y: pattern.y,
            width: pattern.width,
            height: pattern.height,
            rotation: pattern.rotation,
            scaleX: pattern.scaleX,
            scaleY: pattern.scaleY
        });

        // Loop through each sub-element in the pattern
        for (const elem of pattern.elems) {
            const imageObj = new Image();
            imageObj.onload = () => {
                const image = new Konva.Image({
                    image: imageObj,
                    x: elem.x,
                    y: elem.y,
                    width: elem.width,
                    height: elem.height
                });
                group.add(image);
                layer.draw(); // Draw the layer to update the view
            };
            imageObj.onerror = () => {
                console.error('Failed to load image with src:', elem.image);
            };
            imageObj.src = elem.image; // Set the source for the image
        }

        layer.add(group);
        return layer;
    }

    // Path element creation function
    createPathLayer(id, x, y, data, fill, stroke, strokeWidth, scaleX, scaleY, rotation) {
        const layer = new Konva.Layer({ id });

        const path = new Konva.Path({
            x,
            y,
            data,
            fill,
            stroke,
            strokeWidth,
            scaleX,
            scaleY,
            rotation
        });

        layer.add(path);
        return layer;
    }

    createTextLayer(id, fill, fontFamily, fontSize, height, placement, rotation, scaleX, scaleY, text, x, y, data, width) {
        const layer = new Konva.Layer({ id: id });
        const konvaText = new Konva.TextPath({
            fill: fill,
            fontFamily: fontFamily,
            fontSize: fontSize,
            scaleX: scaleX,
            scaleY: scaleY,
            data: data,
            text: text,
            x: x,
            y: y,
            width: width,
            height: height,
            rotation: rotation,
        });
        layer.add(konvaText);
        return layer;
    }

    createLayerWithImage(image, x, y, id, width, height, rotation) {
        const layer = new Konva.Layer({ id: id });
        const konvaImage = new Konva.Image({
            image: image,
            width: width,
            height: height,
            rotation: rotation,
            x: x,
            y: y,
        });
        layer.add(konvaImage);
        return layer;
    }

    createLayerWithElementImage(image, x, y, id, group, width, height, rotation) {
        const layer = new Konva.Layer({ id: id });
        const konvaImage = new Konva.Image({
            image: image,
            x: x,
            y: y,
            width: width,
            height: height,
            rotation: rotation,
        });
        group.add(konvaImage);
        layer.add(group);

        return layer;
    }

    //scaleStageToFitContainer(template) {
    //    const scale = Math.min(
    //        this.stage.width() / template.print_width,
    //        this.stage.height() / template.print_height
    //    );
    //    this.stage.scale({ x: scale, y: scale });
    //    this.stage.draw();
    //}

    scaleStageToFitContainer(template) {
        const container = document.getElementById('product-gallery-container');
        if (!container || !template) {
            console.error('Invalid container or template data.');
            return;
        }

        const width = container.offsetWidth;
        const height = container.offsetWidth * 2;

        const scaleX = width / template.print_width;
        const scaleY = height / template.print_height;
        const scale = Math.min(scaleX, scaleY);

        this.stage.width(template.print_width * scale);
        this.stage.height(template.print_height * scale);
        this.stage.scale({ x: scale, y: scale });
        this.stage.draw();
    }





    loadKonvaCanvas(productTemplateMaps, productDesigns) {
        const container = document.getElementById('product-gallery-container');
        if (!container) {
            console.error('Konva container element not found.');
            return;
        }

        this.stage = new Konva.Stage({
            container: 'product-gallery-large-photo',
            width: container.offsetWidth,
            height: container.offsetWidth * 2,
        });

        window.addEventListener('resize', this.resizeKonvaContainer.bind(this, productTemplateMaps[0], productDesigns));
        this.createKonvaGallery(productTemplateMaps, productDesigns);
    }

    resizeKonvaContainer(templateMap, productDesigns) {
        const container = document.getElementById('product-gallery-container');
        if (!container) return;

        const width = container.offsetWidth;
        const height = width * 2;

        this.stage.width(width);
        this.stage.height(height);
        this.stage.scale({ x: 1, y: 1 }); // Reset scale
        this.updateMainImage(templateMap, productDesigns);
    }

    fitStageIntoParentContainer() {
        console.log('Fitting stage into parent container');
        const container = document.getElementById('product-gallery-large-photo');
        if (!container || !this.stage) return;

        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;

        const stageWidth = this.stage.width();
        const stageHeight = this.stage.height();

        const scaleX = containerWidth / stageWidth;
        const scaleY = containerHeight / stageHeight;
        const scale = Math.min(scaleX, scaleY);

        this.stage.width(stageWidth * scale);
        this.stage.height(stageHeight * scale);
        this.stage.scale({ x: scale, y: scale });

        this.stage.batchDraw();
    }

    loadProduct() {
        //const productId = this.data.get('id');
        const productId = this.idValue;

        axios.get(`/api/v1/products/${productId}`)
            .then(response => {
                const product = response.data['product'];
                this.updateUI(product.combined_photos_and_mockups, product.product_template_maps, product.product_design.state);
            })
            .catch(error => console.error(error));
    }

    async updateUI(productPhotos, productTemplateMaps, productDesigns) {
        if (productPhotos.length === 0) {
            try {
                document.getElementById('konva-container').style.display = 'block';

                await this.loadKonvaLibrary();
                this.loadKonvaCanvas(productTemplateMaps, productDesigns);
            } catch (error) {
                console.error(error);
            }
        } else {
            // logic to load product photos
            this.loadProductPhotos(productPhotos);
        }
    }

    loadProductPhotos(productPhotos) {
        const gallery = document.getElementById('product-gallery-thumbnail-list');
        const largePhoto = document.getElementById('product-gallery-large-photo');
        gallery.innerHTML = ''; // Clear out any existing images

        // Show the first image as the large photo
        if (productPhotos.length > 0) {
            largePhoto.innerHTML = `<img 
                                src="${productPhotos[0].url}" 
                                alt="${productPhotos[0].label}" 
                                class="product-photo-large"
                                style="background-color: ${this.colorCodeValue}"
                                >`;
        }

        productPhotos.forEach((photo, index) => {
            const img = document.createElement('img');
            img.src = photo.url;
            img.alt = photo.label;
            img.classList.add('product-gallery-thumblist-item'); // Add a class for styling
            img.dataset.index = index; // Store the index of the photo
            img.style.backgroundColor = this.colorCodeValue; // Use the color code

            img.addEventListener('click', () => this.updateLargePhoto(photo));

            gallery.appendChild(img);
        });
    }

    updateLargePhoto(photo) {
        const largePhoto = document.getElementById('product-gallery-large-photo');
        largePhoto.innerHTML = `<img 
                                src="${photo.url}" 
                                alt="${photo.label}" 
                                class="product-photo-large" 
                                style="background-color: ${this.colorCodeValue}"
                                >`;
    }

    handleScroll() {
        const gallery = document.querySelector('.product-gallery-container');
        const productDetailsElement = document.querySelector('.product-details');
        const productPhotoLarge = document.querySelector('.product-photo-large');

        if (productDetailsElement && gallery && productPhotoLarge) {
            const textAreaTop = productDetailsElement.getBoundingClientRect().top;

            if (gallery.getBoundingClientRect().bottom <= textAreaTop) {
                gallery.style.position = 'relative';
            } else {
                gallery.style.position = 'sticky';
                gallery.style.top = '0';
                const imageHeight = document.querySelector('.product-photo-large').getBoundingClientRect().height;
                const PADDING = 25;
                gallery.style.height = `${imageHeight + PADDING}px`;
            }
        }

    }

}
