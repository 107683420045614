import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="product-images"
export default class extends Controller {
  connect() {
    let productGallery = document.querySelector('.product-gallery');

    // if productGallery is not null
    if (productGallery)
    {
      // add listener to each thumbnail product-gallery-thumblist-item
      let productGalleryThumblist = document.querySelector('.product-gallery-thumblist');

      let productGalleryThumblistItems = productGalleryThumblist.querySelectorAll('.product-gallery-thumblist-item');
      productGalleryThumblistItems.forEach(function(item) {
        item.addEventListener('click', function(e) {
          e.preventDefault();
          // remove active class from all product-gallery-thumblist-item
          productGalleryThumblistItems.forEach(function(item) {
            item.classList.remove('active');
          });

          // add active class to clicked product-gallery-thumblist-item not the image
          item.classList.add('active');

          // get the data-image-index from the clicked product-gallery-thumblist-item
          let index = item.dataset.imageIndex;

          // get the image of the clicked product-gallery-thumblist-item
          let image = productGallery.querySelector('.product-gallery-preview-item[data-index="' + index + '"]');

          // remove active class from all product-gallery-image
          let productGalleryImages = productGallery.querySelectorAll('.product-gallery-preview-item');
          productGalleryImages.forEach(function(image) {
            image.classList.remove('active');
          });

          // add active class to the image of the clicked product-gallery-thumblist-item
          image.classList.add('active');
        });
      });
    }


  }
}
