// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as $ from 'jquery'
import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap; // This exposes the bootstrap to the global scope

import "@hotwired/turbo-rails";

import axios from 'axios';

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

$(document).on('ajax:success', '#new_user_sign_in', function(event) {
  const [data, status, xhr] = event.detail;
  let responseData;

  if (typeof data === "string") {
    try {
      responseData = JSON.parse(data);
    } catch (e) {
      console.error("Failed to parse JSON:", e);
      return;
    }
  } else if (typeof data === "object") {
    responseData = data;
  } else {
    console.error("Unexpected data type:", typeof data);
    return;
  }

  if (responseData.success) {
    document.querySelector('meta[name="csrf-token"]').setAttribute('content', data.csrf_token);
    axios.defaults.headers.common['X-CSRF-Token'] = data.csrf_token;
    setTimeout(() => {
      $('#loginModal').modal('hide');
    }, 100);
    //axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    // Fetch the updated user menu
    $.get("/users/user_menu");
    const event = new Event('userAuthenticated');
    window.dispatchEvent(event);
  } else {
    alert(responseData.errors);
  }
});


$(document).on('ajax:success', '#registration-form', function(event) {
  const [data, status, xhr] = event.detail;

  if (data.success) {
    document.querySelector('meta[name="csrf-token"]').setAttribute('content', data.csrf_token);
    axios.defaults.headers.common['X-CSRF-Token'] = data.csrf_token;
    // Handle successful registration, like hiding the modal
    setTimeout(() => {
      $('#loginModal').modal('hide');
    }, 100);
    //axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    // Fetch the updated user menu
    $.get("/users/user_menu");
    const event = new Event('userAuthenticated');
    window.dispatchEvent(event);
  } else {
    // Handle any registration errors
    alert(data.errors.join("\n"));
  }
}).on('ajax:error', function(event) {
  const [xhr, status, error] = event.detail;
  const serverResponse = error.responseText;

  try {
    const responseData = JSON.parse(serverResponse);
    if (responseData.errors && responseData.errors.length > 0) {
      alert(responseData.errors[0]); // Display the first error message
    } else {
      alert("An error occurred. Please try again.");
    }
  } catch (e) {
    console.error("Failed to parse error response:", e);
    alert("An error occurred. Please try again.");
  }
});

require("@rails/activestorage").start()
import "@fortawesome/fontawesome-free/js/all";

// Begin Stimulus
// Use 'rails g stimulus <controller_name>' to generate a new controller
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
// End Stimulus

document.addEventListener("turbo:load", () => {
  // Both of these are from the Bootstrap 5 docs
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })
})


// The following is for dynamic drop down population. See New Admin::Product
$(function() {
  $(document).on('change', '.remote-select', function(e) {
    let url = $(this).find(':selected').attr('data-url');
    let target = $(this).attr('data-target');

    if (url) {
      $.ajax({
        url: url,
        method: 'GET',
        success: function (json) {
          let target_select = $(target);
          target_select.empty();
          json.map(function (item) {
            let value = $('<option></option>').attr('value', item.id).text(item.variant_name);
            target_select.append(value);
          })
        },
        error: function (err) {
        }
      })
    }
  })});


/* Mobile Hamburger Toggle */
document.addEventListener("turbo:load", function() {
  const toggler = document.getElementById('mobile-navbar-toggler');
  const menu = document.getElementById('mobile-navbar-menu');

  if (toggler && menu) {
    toggler.addEventListener('click', function() {
      if (menu.style.display === "none" || menu.style.display === "") {
        menu.style.display = "contents";
      } else {
        menu.style.display = "none";
      }
    });
  }
});

document.addEventListener('turbo:load', function() {
  const colorCircles = document.querySelectorAll('.color-circle');

  colorCircles.forEach(circle => {
    circle.addEventListener('mouseover', function() {
      const color = this.getAttribute('data-color');
      const productImage = document.querySelector(`#product-image-${this.getAttribute('data-product-id')}`);
      if (productImage) {
        productImage.style.backgroundColor = color;
      }
    });
  });
});


